import React from 'react'
import {graphql} from 'gatsby'

//import StudentWorkPreviewList from '../components/student-work-preview-list'
import Container from '../components/container'
import About from '../components/about'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    about: sanityBio(_id: { regex: "/(drafts.|)bio/" }) {
      image {
        ...SanityImage
        alt
        caption
      }
      _rawBio
      pdf {
        title
        file {
          asset {
            url
          }
        }
      }
      teachingPdf {
        title
        file {
          asset {
            url
          }
        }
      }
      researchPdf {
        title
        file {
          asset {
            url
          }
        }
      }
    }

  }
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const about = (data || {}).about
  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
        image={about.image}
      />
      <Container>
        
        {about._rawBio && about.image && about.pdf &&  (
          <About
            title='Bio'
            body={about._rawBio}
            image={about.image}
            pdf={about.pdf}
            teachingPdf={about.teachingPdf}
            researchPdf={about.researchPdf}
          />
        )}

      </Container>
    </Layout>
  )
}

export default AboutPage